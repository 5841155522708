export default function RespInfo(resp) {
    if (resp.status === 201) {
        return {
            duration: 1500,
            message: "Created",
            position: 'is-bottom',
            type: 'is-success'
        }
    } else if (resp.status === 200) {
        return {
            duration: 1500,
            message: "OK",
            position: 'is-bottom',
            type: 'is-success'
        }
    } else {
        let err_message = resp.statusText;
        if (resp.data && resp.status !== 500)
            err_message += '<br>' + resp.data;
        return {
            duration: 5000,
            message: err_message,
            position: 'is-bottom',
            type: 'is-danger'
        };
    }
}
