<template>
    <section>
        <b-modal :active="isActive" v-on:close="close" class="audio-message-modal">
            <div class="am-modal-card">
                <header class="modal-card-head">
                    <div class="close-button">
                        <b-button class="am-close-button is-danger" @click="close">
                            <b-icon icon="close"/>
                        </b-button>
                    </div>
                </header>
                <section class="modal-card-body">
                    <div>
                        <button class="button is-rounded is-success" v-if="!isRecording" @click="startRecording">
                            Start recording
                        </button>
                        <button class="button is-rounded is-danger" v-else @click="stopRecording">Stop recording
                        </button>
                    </div>
                    <div class="am-analyser">
                        <vue-dictaphone-spectrum-analyser :width="256" :height="64"/>
                    </div>
                    <div>
                        <div v-if="durationError" class="am-error">
                            <p>Duration is not valid:
                                <span v-if="minDuration">min - {{ minDuration }} sec, </span>
                                <span v-if="maxDuration">max - {{ maxDuration }} sec.</span>
                            </p>
                        </div>
                        <div v-if="audioSource" class="am-player">
                            <audio id="audioPreview" :src="audioSource" controls controlsList="nodownload"></audio>
                        </div>
                        <div v-if="timer" class="am-timer">{{
                                new Date(timerCount * 1000).toISOString().substr(14, 5)
                            }}
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <div style="width: 100%">
                        <b-button :disabled="!isSendButtonActive" type="is-rounded is-success" @click="sendAudio">
                            {{ buttonText }}
                        </b-button>
                    </div>
                </footer>
            </div>
        </b-modal>
    </section>
</template>
<script>
    import VueDictaphone from "vue-dictaphone";
    import Vue from "vue";

    Vue.use(VueDictaphone);

    const MicRecorder = require('mic-recorder-to-mp3');
    const recorder = new MicRecorder({
        bitRate: 128
    });

    export default {
        name: "AudioMessage",
        props: {
            isActive: Boolean,
            room_id: Number,
            minDuration: {
                type: Number,
                default: 0,
            },
            maxDuration: {
                type: Number,
                default: 1800, // 30 min
            },
            buttonText: {
                type: String,
                default: "Send"
            },
            isTemplate: {
                type: Boolean,
                default: false,
            }
        },
        methods: {
            startRecording() {
                this.setDefaultState();
                recorder.start().then(() => {
                    this.isRecording = true;
                    this.startTimer()

                }).catch((e) => {
                    window.console.error(e);
                });
            },
            stopRecording() {
                recorder
                    .stop()
                    .getMp3().then(([buffer, blob]) => {
                    // do what ever you want with buffer and blob
                    // Example: Create a mp3 file and play
                    this.file = new File(buffer, 'me-at-thevoice.mp3', {
                        type: blob.type,
                        lastModified: Date.now()
                    });
                    this.audioSource = URL.createObjectURL(this.file);
                    this.isRecording = false;
                    this.stopTimer();
                    this.duration = this.timerCount;
                    this.isSendButtonActive = true;
                }).catch((e) => {
                    alert('We could not retrieve your message');
                    window.console.log(e);
                });
            },
            stopTimer() {
                if (this.timer) {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            },
            setDefaultState(durationError = false) {
                this.durationError = durationError
                this.file = null;
                this.audioSource = null;
                this.isSendButtonActive = false;
                this.timerCount = 0;
            },
            close() {
                this.setDefaultState()
                this.$emit('close');
            },
            startTimer() {
                this.setDefaultState();
                this.timer = setInterval(() => {
                    this.timerCount++;
                }, 1000);
            },
            sendAudio() {
                if (this.duration >= this.minDuration && this.duration <= this.maxDuration) {
                    this.sendFile(this.file, 'audio/mpeg', 'audio-message')
                } else {
                    this.setDefaultState(true);
                }
            },
            async sendFile(data, type, filename) {
                const resp = await this.$store.dispatch('FILE_UPLOAD', {data: data, type: type});
                let messageType = 'audio';
                const message = {
                    "type": messageType,
                    "value": resp.data,
                    'file_name': filename,
                    'duration': this.duration,
                };
                if (this.isTemplate) {
                    this.getAudioMessageData(resp.data);
                } else {
                    await this.$store.dispatch('CHAT_POST', {room_id: this.room_id, message: message});
                }
                this.close();
            },
            getAudioMessageData(audioSrc) {
                let data = '<p dir="rtl" style="text-align: right;"><audio id="audioPreview" src="' + audioSrc + '" controls controlsList="nodownload"></audio></p>';
                this.$emit('save', data);
            }
        },
        data() {
            return {
                blob: null,
                audioSource: null,
                isSendButtonActive: false,
                durationError: false,
                duration: 0,
                timerCount: 0,
                timer: null,
                isRecording: false,
            }
        }
    }
</script>

<style>
    .audio-message-modal {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        font-size: 18px;
    }

    .audio-message-modal .am-modal-card {
        width: 440px;
        height: 354px;
    }

    .audio-message-modal .modal-content {
        width: 440px;
    }

    .audio-message-modal .modal-card-head {
        height: 60px;
    }

    .audio-message-modal .modal-card-foot {
        height: 60px;
    }

    .audio-message-modal .modal-card-body {
        height: 210px;
        overflow: hidden;
    }

    .audio-message-modal .am-analyser {
        padding-top: 16px;
        width: auto;
        height: 64px;
    }

    .audio-message-modal .am-timer {
        padding-top: 31px;
        font-size: 24px;
    }

    .audio-message-modal .am-error {
        color: red;
        padding-top: 31px;
    }

    .audio-message-modal .am-player {
        padding-top: 31px;
    }

    .audio-message-modal .am-player audio {
        height: 36px;
    }

    .audio-message-modal .am-close-button {
        width: 28px;
        height: 28px;
        border-radius: 5px;
    }

    .audio-message-modal .am-close-button .icon {
        margin-top: 3px;
    }

    .audio-message-modal .modal-card-head {
        padding: 16px;
    }
</style>