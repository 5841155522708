<template>
    <b-modal :active="isActive">
        <form action="" @submit.prevent="sendTemplate">
            <div class="modal-card" style="width: auto; height: 100%">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{title}}</p>
                </header>
                <section class="modal-card-body">
                    <b-collapse class="card"
                                animation="slide"
                                v-for="category in getCategories"
                                :key="category.id">
                        <div slot="trigger"
                             slot-scope="props"
                             class="card-header"
                             role="button">
                            <p class="card-header-title">
                                {{ category.name }}
                            </p>
                            <a class="card-header-icon">
                                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                                </b-icon>
                            </a>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <div class="template" v-for="template in getChatTemplates(category.id)"
                                     v-bind:key="template.id"
                                     :template="template.id"
                                     @click="selectTemplate(template)" >
                                    <b>{{ template.name }}</b>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-rounded is-danger" type="button" @click="closeModal">Close</button>
                    <button class="button is-rounded is-success"> Send </button>
                    <div v-if="message!==undefined">
                        <b>{{message}}</b><i v-if="template!==undefined">{{template.name}}</i>
                    </div>
                </footer>
            </div>
        </form>
    </b-modal>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "ClientChatTemplates",
        props:{
            isActive: Boolean,
            room_id: Number,
        },
        data() {
            return {
                title: 'Send Chat Template',
                message: 'Select template',
                template: undefined,
            }
        },
        created() {
            this.refresh();
        },
        computed: {
            ...mapGetters(['getChatTemplatesCategories', 'getChatTemplates']),
            getCategories: function () {
                const that = this;
                let categories = JSON.parse(JSON.stringify(that.getChatTemplatesCategories));
                return categories.sort((a, b) => a.name.localeCompare(b.name))
            },
        },
        methods: {
            refresh() {
                this.$store.dispatch('CHAT_TEMPLATES_CATEGORIES_GET');
            },
            closeModal() {
                this.$emit('close');
            },
            selectTemplate(payload) {
                this.message = 'Selected template name: '
                this.template = payload;
            },
            async sendTemplate() {
                if(this.template!==undefined){
                    await this.$store.dispatch('CHAT_POST_TEMPLATE',{room_id: this.room_id, template_id:this.template.id})
                    this.closeModal()
                } else {
                    this.$buefy.dialog.alert('You should select a template before sending');
                }
            }
        }

    }
</script>

<style scoped>
    .template {
        border: 1px solid lightgray;
        border-radius: 5px;
        height: 40px;
        text-align: left;
        padding: 5px;
        margin: 5px;
    }

    .card-content {
        padding: 5px;
    }
</style>