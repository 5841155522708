<template>
    <div class="client_chat">
        <div class="client_chat_header">
            <b>questionsOnly </b>
            <b-checkbox v-model="onlyQuestions"/>
        </div>
        <ul v-if="Array.isArray(messages)"
            :class="{'client_chat_field':true, 'hasinput':isInputEnabled, 'noinput':!isInputEnabled}"
            v-chat-scroll="{always: false, smooth: true}"
            v-on:scroll="getScrollPosition">
            <li v-for="(day, keyo) in messages" v-bind:key="keyo">
                <div class="day">{{ day.day }}</div>
                <div v-for="(msg, key) in day.messages" v-bind:key="key"
                     :class="{'right_to_left': isRtl, 'left_to_right': !isRtl}">
                    <b-checkbox v-if="show_delete_panel" v-model="marked_delete_messages"
                        :native-value="msg.id">
                    </b-checkbox>
                    <section v-if="onlyQuestions">
                        <p class="chat_message client" v-if="msg.data.type=='answer'">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            {{ msg.data.value }}
                        </p>
                        <p class="chat_message" v-if="msg.data.type=='question'">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <b-icon v-if="msg.data.type=='question'" icon="help-circle-outline"/>
                            {{ msg.data.value }}
                        </p>
                    </section>
                    <section v-else>
                        <p v-if="msg.data.type =='glucose_report'"
                           class="chat_message glucose client"
                           v-bind:class="getGlucoseFit(msg.data.value )">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span>glucose level: {{ Math.round(msg.data.value) }}</span>
                        </p>
                        <p v-else-if="msg.data.type =='nutrition_report'" class="chat_message client file">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span class="chat_content">
                                <img class="nutrition" :src="msg.data.value" @click="showImage(msg.data.value)"/>
                            </span>
                        </p>
                        <p v-else-if="msg.data.type =='food_report'" class="chat_message client file">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span class="chat_content">
                        <div><b>food report:</b></div>
                        <div v-if="msg.data.items!==null">
                            <b>selected items: </b>
                            <span v-for="(item, item_key) in msg.data.items" :key="'item_key_'+item_key">{{ item.name }}<span
                                    v-if="item_key<msg.data.items.length-1">,</span> </span>
                        </div>
                        <div v-if="msg.data.text!==null">
                            <b>described items: </b>
                            <span v-for="(item, text_item_key) in msg.data.text" :key="'text_item_key_'+text_item_key">{{
                                    item.name
                                }}<span
                                        v-if="text_item_key<msg.data.text.length-1">,</span> </span>
                        </div>
                        <img v-if="msg.data.image_path!==null" class="nutrition" :src="msg.data.image_path"
                             @click="showImage(msg.data.image_path)"/>
                        </span>
                        </p>
                        <p v-else-if="msg.data.type =='exercise_report'" class="chat_message client file">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span class="chat_content">
                                exercise <br/>
                                {{ msg.data.minutes }} min <br/>
                                <span v-if="msg.data.hasOwnProperty('steps')"> {{ msg.data.steps }}</span>
                            </span>
                        </p>
                        <p v-else-if="msg.data.type =='blood_pressure_report'" class="chat_message client file">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span class="chat_content">
                                blood pressure <br/>
                                {{ msg.data.systolic }}/{{ msg.data.diastolic }}
                            </span>
                        </p>
                        <p v-else-if="msg.data.type =='medicine_report'" class="chat_message client file">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span v-if="msg.data.item" class="chat_content">
                                <b>medicine reported <br/>
                                took a </b>{{ msg.data.item.name }}
                            </span>
                            <span v-else class="chat_content">
                                <b>medicine reported <br/>
                                took </b>{{ msg.data.items.map(item => item.name).join(', ') }}
                            </span>
                        </p>
                        <p v-else-if="msg.data.type =='supplement_report'" class="chat_message client file">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span v-if="msg.data.item" class="chat_content">
                                <b>supplement reported <br/>
                                took a </b>{{ msg.data.item.name }}
                            </span>
                            <span v-else class="chat_content">
                                <b>supplement reported <br/>
                                took </b>{{ msg.data.items.map(item => item.name).join(', ') }}
                            </span>
                        </p>
                        <p v-else-if="msg.data.type =='weight_report'" class="chat_message client file">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span class="chat_content">
                                <b>weight reported <br/>
                                </b>{{ msg.data.value }} kg
                            </span>
                        </p>
                        <p v-else-if="msg.data.type =='water_report'" class="chat_message client file">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span class="chat_content">
                                <b>water reported <br/>
                                </b>{{ msg.data.number }} cups
                            </span>
                        </p>
                        <p v-else-if="msg.data.type =='image'&&msg.sender!==leader_id" class="chat_message client file">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span class="chat_content">
                                <img class="nutrition" :src="msg.data.value" @click="showImage(msg.data.value)"/>
                            </span>
                        </p>
                        <p v-else-if="msg.data.type =='image'&&msg.sender===leader_id" class="chat_message file">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span class="chat_content">
                                <img class="nutrition" :src="msg.data.value" @click="showImage(msg.data.value)"/>
                            </span>
                        </p>
                        <p v-else-if="msg.data.type =='pdf'&&msg.sender!==leader_id" class="chat_message client file">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span class="chat_content">
                                <a :href="'http://docs.google.com/gview?url='+msg.data.value+'&embedded=true'"
                                   target="_blank">
                                    <b-icon icon="file-pdf" size="is-large"/><br/>
                                    {{ msg.data.file_name }}
                                 </a>
                            </span>
                        </p>
                        <p v-else-if="msg.data.type =='pdf'&&msg.sender===leader_id" class="chat_message file">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span class="chat_content">
                                <a :href="'http://docs.google.com/gview?url='+msg.data.value+'&embedded=true'"
                                   target="_blank">
                                    <b-icon icon="file-pdf" size="is-large"/><br/>
                                    {{ msg.data.file_name }}
                                </a>
                            </span>
                        </p>
                        <p v-else-if="msg.data.type =='file'&&msg.sender!==leader_id" class="chat_message client">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <a :href="msg.data.value"
                               target="_blank"><br/>
                                {{ msg.data.file_name }}
                            </a>
                        </p>
                        <p v-else-if="msg.data.type =='file'&&msg.sender===leader_id" class="chat_message">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <a :href="msg.data.value"
                               target="_blank"><br/>
                                {{ msg.data.file_name }}
                            </a>
                        </p>
                        <p v-else-if="msg.data.type =='sleep_report'" class="chat_message client sleep">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span v-if="msg.data.is_wakeup">wakeup at: {{ msg.data.value }}</span>
                            <span v-else>went sleep at: {{ msg.data.value }}</span>
                        </p>
                        <p v-else-if="msg.data.type =='rich_text'&&msg.sender!==leader_id" class="chat_message client"
                           :class="{'right_to_left': isRtl, 'left_to_right': !isRtl}">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span class="content">
                                <span v-html="msg.data.value"></span>
                            </span>
                        </p>
                        <p v-else-if="msg.sender!==leader_id" class="chat_message client"
                           :class="{'right_to_left': isRtl, 'left_to_right': !isRtl}">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span class="content">
                                <span v-html="msg.data.value"></span>
                            </span>
                        </p>
                        <p v-else-if="msg.data.type =='audio'" class="chat_message file"
                           :class="(msg.sender!==leader_id)?'client':''">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <span class="chat_content">
                                <audio :src="msg.data.value" controls controlsList="nodownload"></audio>
                            </span>
                        </p>
                        <p v-else class="chat_message" :class="{'right_to_left': isRtl, 'left_to_right': !isRtl}">
                            <span class="timestamp">{{ getMessageTime(msg.sent_at) }}</span>
                            <b-icon v-if="msg.data.type=='question'" icon="help-circle-outline"/>
                            <span class="content">
                                <span v-html="msg.data.value"></span>
                            </span>
                            <span v-if="msg.data.hasOwnProperty('buttons')&&msg.data.buttons.length > 0"
                                  class="content">
                                <span v-for="(btn, key) in msg.data.buttons" :key="key">
                                    <b-button type="is-success is-small is-rounded"
                                              disabled>{{ btn.caption }}</b-button>
                                </span>
                            </span>
                        </p>
                    </section>
                </div>
            </li>
        </ul>
        <div class="client_chat_input">
            <table>
                <tbody>
                <tr>
                    <td v-if="show_delete_panel" style="text-align: left">
                        <b-button :disabled="marked_delete_messages.length === 0" type="is-danger is-small is-rounded" @click="deleteSelectedMessages()">Delete</b-button>
                    </td>
                    <td style="text-align: right;padding: 10px 5px 0 5px">
                        <input v-if="!isInputEnabled"
                               type="file"
                               id="files"
                               ref="files"
                               class="inputfile"
                               @change="onChangeFile"
                               accept="image/*, .pdf, audio/mpeg">
                        <label for="files" v-if="!isInputEnabled"><img style="height: 20px" src="../../assets/svg/chat_send_file.svg"/></label>
                        <a @click="showEditor">
                            <b-icon icon="pencil"/>
                        </a>
                        <a @click="isAudioMessageActive=true">
                            <b-icon icon="microphone"/>
                        </a>
                        <a @click="isTemplatesActive=true">
                            <b-icon icon="code-brackets"/>
                        </a>
                        <a @click="isRtl = false">
                            <b-icon icon="format-align-left" :class="{'icon_active':!isRtl, 'icon_unactive':isRtl,}"/>
                        </a>
                        <a @click="isRtl = true">
                            <b-icon icon="format-align-right" :class="{'icon_unactive':!isRtl, 'icon_active':isRtl, }"/>
                        </a>
                        <a @click="show_delete_panel = !show_delete_panel">
                            <b-icon icon="delete" :class="{'icon_active':!show_delete_panel, 'icon_unactive':show_delete_panel,}"/>
                        </a>
                    </td>
                    <td></td>
                </tr>
                <tr v-if="isInputEnabled">
                    <td>
                        <b-input type="textarea"
                                 v-model="message"
                                 @keyup.native.enter="sendMessage"
                                 :dir="getDirection"
                                 :custom-class="getCustomInputClass"/>
                    </td>
                    <td width="40px">
                        <b>is ?</b>
                        <b-checkbox v-model="isQuestion" class="is-small"/>
                        <br/>
                        <input v-if="isInputEnabled"
                               type="file"
                               id="files"
                               ref="files"
                               class="inputfile"
                               @change="onChangeFile"
                               accept="image/*, .pdf">
                        <label for="files"><img src="../../assets/svg/chat_send_file.svg"/></label>
                        <a @click="sendMessage">
                            <img src="../../assets/svg/chat_send_btn.svg"/>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <img :src="image"/>
        <view-image-modal v-if="isImageModalActive"
                          :src="modalImagePath"
                          :is-active="isImageModalActive"
                          v-on:close="isImageModalActive=false"/>
        <rich-text-editor v-if="isRTEActive"
                          :is-active="isRTEActive"
                          v-on:close="isRTEActive=false"
                          :room_id="room_id"/>
        <client-chat-templates v-if="isTemplatesActive"
                               :is-active="isTemplatesActive"
                               v-on:close="isTemplatesActive=false"
                               :room_id="room_id"/>
        <audio-message :is-active="isAudioMessageActive"
                       v-on:close="isAudioMessageActive=false"
                       :room_id="room_id"
        />
    </div>

</template>

<script>
    import moment from 'moment';
    import {mapGetters, mapState} from 'vuex'
    import ViewImageModal from "../ViewImageModal";
    import RichTextEditor from "./RichTextEditor";
    import ClientChatTemplates from "./ClientChatTemplates";
    import AudioMessage from "./AudioMessage";

    export default {
        name: "ClientChat",
        props: {
            clientId: String,
            room_id: Number,
            has_input: Boolean,
        },
        components: {
            AudioMessage,
            ViewImageModal,
            RichTextEditor,
            ClientChatTemplates,
        },
        data() {
            return {
                message: '',
                show_messages: [],
                leader_id: '',
                messages: [],
                chatscroll: 0,
                hold: false,
                loadtop: 5,
                isQuestion: false,
                onlyQuestions: false,
                isImageModalActive: false,
                isTemplatesActive: false,
                isAudioMessageActive: false,
                modalImagePath: '',
                files: [],
                image: '',
                isRtl: false,
                isRTEActive: false,
                isInputEnabled: false,
                marked_delete_messages: [],
                show_delete_panel: false,
            }
        },
        created() {
            this.leader_id = this.getAuthId;
            if (this.has_input !== undefined) this.isInputEnabled = this.has_input;
            this.refresh();
            //this.isInputEnabled = true;
        },
        computed: {
            ...mapGetters(['getAuthId', 'getLastRead']),
            ...mapState(['socket', 'chat']),
            getDirection() {
                if (this.isRtl) return 'rtl';
                return 'ltr';
            },
            getCustomInputClass() {
                if (this.isRtl) {
                    return 'right_to_left';
                }
                return 'left_to_right';
            }

        },
        methods: {
            async clearChat() {
                this.hold = true;
                await this.$store.dispatch('CHAT_CLEAR');
                this.hold = false;
                this.$store.dispatch('CHAT_READ_ALL', this.room_id);
            },
            loadChat: async function () {
                this.hold = true;
                await this.$store.dispatch('CHAT_GET', {'room_id': this.room_id, 'top': this.loadtop});
                this.hold = !(this.loadtop === this.getLastRead);
                var h = 20 + 59 * this.getLastRead;
                var el = document.getElementsByClassName('client_chat_field')[0];
                el.scrollTop += h;
            },
            async refresh() {
                await this.clearChat();
                var p = true;
                var indexx = 0;
                while (p) {
                    p = await this.getScrollPosition();
                    var scTop = document.getElementsByClassName('client_chat_field')[0].scrollTop;
                    indexx += 1;
                    this.$log.info(indexx + ') top=' + scTop + ' p=' + p)
                }
            },
            sendMessage() {
                const expr = /\n/g;
                const mess = this.message.replace(expr, '');
                this.message = '';
                if (mess.length > 0) {
                    const message = {
                        "type": "text",
                        "value": mess,
                    };
                    if (this.isQuestion) message.type = "question";
                    this.$store.dispatch('CHAT_POST', {room_id: this.room_id, message: message});
                    this.$log.info('message: ' + JSON.stringify(message));
                } else {
                    this.$log.info('empty message');
                }
            },
            onChangeFile(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length) return;
                this.prepareFile(files[0])
            },
            prepareFile(file) {
                var reader = new FileReader();
                var vm = this;
                reader.onload = (e) => {
                    vm.sendFile(e.target.result, file.type, file.name);
                };
                reader.readAsArrayBuffer(file);
            },
            async sendFile(data, type, filename) {
                const resp = await this.$store.dispatch('FILE_UPLOAD', {data: data, type: type});
                let messageType = 'file';
                if (type === 'image/jpeg') messageType = 'image';
                if (type === 'image/gif') messageType = 'image';
                if (type === 'image/bmp') messageType = 'image';
                if (type === 'image/png') messageType = 'image';
                if (type === 'audio/mpeg') messageType = 'audio';
                if (type === 'application/pdf') messageType = 'pdf';
                const message = {
                    "type": messageType,
                    "value": resp.data,
                    'file_name': filename,
                };
                await this.$store.dispatch('CHAT_POST', {room_id: this.room_id, message: message});
            },
            groupByDay() {
                const date = new moment(new Date());
                const today = date.format("YYYY-MM-DD");
                const yesterday = date.clone().subtract(1, "days").format("YYYY-MM-DD");
                let days = [];
                let oldsent = 'old';
                let index = -1;
                for (let message of this.show_messages) {
                    const sdate = new moment(new Date(message.sent_at));
                    let sent = sdate.format("YYYY-MM-DD");
                    if (oldsent.localeCompare(sent)) {
                        days.push({});
                        index = index + 1;
                        //days.length - 1;
                        days[index].day = sent;
                        days[index].messages = [];
                    }
                    days[index].messages.push(message);
                    oldsent = sent;
                }
                for (let day of days) {
                    if (!day.day.localeCompare(today)) day.day = 'today';
                    if (!day.day.localeCompare(yesterday)) day.day = 'yesterday';
                }
                return days;
            },
            async getScrollPosition() {
                let scrollTop = document.getElementsByClassName('client_chat_field')[0].scrollTop;
                if (scrollTop < 50 && !this.hold) {
                    await this.loadChat();
                    scrollTop = document.getElementsByClassName('client_chat_field')[0].scrollTop;
                    return this.loadtop == this.getLastRead && scrollTop < 50;
                }
                return false
            },
            showImage(path) {
                this.modalImagePath = path;
                this.isImageModalActive = true;
            },
            showEditor() {
                this.isRTEActive = true;
            },
            getGlucoseFit(glucose) {
                if (glucose < 80) return 'glucose_red'; // < 80 red
                if (glucose > 120) return 'glucose_black'; // > 120 black
                return '';
            },
            getReportTime(date) {
                const m = moment(date);
                return m.format('HH:mm');
            },
            getMessageTime(date) {
                let d = new Date();
                const m = moment(date).add(-d.getTimezoneOffset(), 'minutes');
                return m.format('HH:mm')
            },
            deleteSelectedMessages() {
                if (this.marked_delete_messages.length > 0) {
                    this.$store.dispatch('CHAT_DELETE', {room_id: this.room_id, messages: this.marked_delete_messages});
                    this.marked_delete_messages = [];
                }
            },
        },
        watch: {
            chat: {
                deep: true,
                handler(chat) {
                    this.show_messages = chat.chat_messages.filter(item => {
                        return item.room_id == this.room_id
                    });
                    this.messages = this.groupByDay()
                }
            },
            show_messages: function () {
                const unreaded = this.show_messages.filter(el => {
                    let found = -1;
                    if (el.hasOwnProperty('read_by'))
                        found = el.read_by.findIndex(element => element == this.leader_id);
                    if (found < 0) return el
                }).map(el => el.id);
                if (unreaded.length > 0) {
                    this.$store.dispatch('CHAT_READ', {room_id: this.room_id, messages: unreaded});
                    // this.loadUnread();
                }
            },
            room_id: {
                handler() {
                    this.refresh();
                }
            },
        }
}
</script>

<style scoped>
    .client_chat {
        height: 100%;
        padding: 10px;
        margin: 0;
        min-height: 350px;
        /*max-width: 800px;*/
        position: relative;
    }

    .day {
        text-align: center;
        font-weight: bold;
        color: gray;
        font-size: small;
    }

    .chat_message {
        position: relative;
        margin: 5px 5px 5px 50px;
        padding: 25px 15px 15px 15px;
        border: 1px solid lightgrey;
        border-radius: 25px 25px 0 25px;
        overflow: auto;
    }

    .chat_content {
        width: 100%;
    }

    .client {
        margin: 5px 50px 5px 5px;
        padding: 15px;
        border: 1px solid lightgrey;
        border-radius: 0 25px 25px 25px;
    }

    .file {
        text-align: center;
    }

    .timestamp {
        position: absolute;
        top: 3px;
        left: 15px;
        font-size: 9pt;
        font-weight: bold;
    }

    .glucose {
        text-align: center;
        border: 1px solid #4CD964;
        background-color: #4CD964;
        color: white;
        font-family: Heebo;
        font-weight: 500;
        font-size: 20px;
        line-height: 18px;
        letter-spacing: -0.08px;
    }

    .sleep {
        border: 1px solid #646464;
        background-color: #646464;
        color: white;
    }

    .client_chat_header {
        position: absolute;
        vertical-align: bottom;
        width: 100%;
        text-align: left;
    }

    .client_chat_field {
        position: absolute;
        vertical-align: bottom;
        width: 100%;
        background-color: white;
        border-radius: 5px;
        border: 1px solid lightgrey;
        top: 50px;
        padding: 7px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .hasinput {
        bottom: 170px;
    }

    .noinput {
        bottom: 35px;
    }

    .client_chat_field_box {
        position: relative;
        border: 1px solid red;
    }

    .client_chat_input {
        position: absolute;
        width: 100%;
        bottom: 1px;
    }

    .client_chat_input table {
        width: 100%;
        vertical-align: bottom;
    }

    .client_chat_input td {
        vertical-align: bottom;
        padding: 5px
    }

    .glucose_red {
        background-color: red;
        border-color: red;
    }

    .glucose_black {
        background-color: black;
        border-color: black;
    }

    .nutrition {
        padding: 15px;
        width: 200px;
        font-family: Heebo;
        font-weight: 500;
        font-size: 20px;
        line-height: 18px;
        letter-spacing: -0.08px;
    }

    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .inputfile + label {
        cursor: pointer; /* "hand" cursor */
    }

    .right_to_left {
        direction: rtl;
        text-align: right;
    }

    .left_to_right {
        direction: ltr;
        text-align: left;
    }

    .icon_active {
        color: red;
    }

    .icon_unactive {
        color: #ffaaaa
    }

    @media (max-width: 768px) {
        .client_chat {
            margin-right: 15px;
        }
    }
</style>
