<template>
    <section>
        <b-modal :active="isActive" v-on:close="$emit('close')" >
            <div class="image-containe">
                <div class="image is-4by3">
                    <img id="image-id" :src="src" v-bind:style="{ transform: transform}">
                </div>
            </div>
            <div class="close-button">
                <b-button class="is-rounded is-success" @click="$emit('close')">
                    <b-icon icon="close"/>
                </b-button>
            </div>
            <div class="image-buttons">
                <b-button class="is-rounded is-success" @click="transform = -90">
                    <b-icon icon="undo"/>
                </b-button>&nbsp;
                <b-button class="is-rounded is-success" @click="refresh">
                    <b-icon icon="refresh"/>
                </b-button>&nbsp;
                <b-button class="is-rounded is-success" @click="transform = 90">
                    <b-icon icon="redo"/>
                </b-button>
                <b-field style="padding: 0 15px 0 15px">
                    <b-slider class="is-small is-success" v-model="rotationAngle" :min="0" :max="359"
                              :tooltip="false"></b-slider>
                </b-field>
            </div>
        </b-modal>
    </section>
</template>

<script>
    export default {
        name: "ViewImageModal",
        props: {
            src: String,
            isActive: Boolean,
        },
        data() {
            return {
                rotationAngle: 0,
                alpha: 0,
                multiplyH: 1,
                windowScale: 1,
                image: {},
            }
        },
        created() {
            let image = new Image();
            image.src = this.src;
            this.image = image;
            this.refresh();
        },
        computed: {
            transform: {
                get() {
                    let rad;
                    let numScale;
                    let angle = this.rotationAngle;
                    rad = (this.rotationAngle) * Math.PI / 180;
                    if (0 <= angle && angle < 90)
                        numScale = this.multiplyH / Math.abs(Math.sin(rad + this.alpha));
                    if (90 <= angle && angle < 180)
                        numScale = this.multiplyH / Math.abs(Math.sin(rad - this.alpha));
                    if (180 <= angle && angle < 270)
                        numScale = this.multiplyH / Math.abs(Math.sin(rad + this.alpha));
                    if (270 <= angle && angle < 360)
                        numScale = this.multiplyH / Math.abs(Math.sin(rad - this.alpha));
                    const scale = 'scale(' + numScale + ')';
                    const rotate = 'rotate(' + this.rotationAngle + 'deg)';
                    return scale + ' ' + rotate;
                },
                set(value) {
                    this.rotationAngle += value;
                    if (this.rotationAngle < 0)
                        this.rotationAngle = 360 + value;
                    else if (this.rotationAngle > 359) this.rotationAngle = 0;
                }
            }
        },
        methods: {
            refresh() {
                const height = this.image.height;
                const width = this.image.width;
                const diag = Math.sqrt(height * height + width * width);
                this.image.diag = diag;
                this.multiplyH = height / diag;
                this.alpha = Math.abs(Math.asin(this.multiplyH));
                this.rotationAngle = 0;
            }
        }
    }
</script>

<style scoped>

    .image-container {
        position: relative;
        height: 100vh;
        overflow: scroll;
        margin: 0;
        padding: 0;
    }

    .image-container img {
        /* allow the width to be calculated dynamically */
        height: auto;
        max-width: 100%;
        /* then move the element back again using a transform */
    }

    .image-buttons {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 20px;
        height: 80px;
        text-align: center;
        padding: 10px;
    }

    .close-button {
        position: fixed;
        top: 10px;
        right: 0;
        width: 100px;
        height: 55px;
    }
</style>