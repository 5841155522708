<template>
    <section>
        <b-modal :active="isActive" v-on:close="$emit('close')">
            <div class="container">
                <editor apiKey="ojvhjg3lin41e0ua4oj1n4gwcsh6t537z13etql77do8c43y" :init="options" v-model="editorData"/>
                <input id="my-file" type="file" name="my-file" style="display: none;" onchange=""/>
                <div class="buttons-block">
                    <b-button type="is-rounded is-danger" @click="cancel">Cancel</b-button>&nbsp;
                    <b-button type="is-rounded is-success" @click="isPreviewActive=true">Preview</b-button>&nbsp;
                    <b-button type="is-rounded is-success" @click="post">Send</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal v-if="isPreviewActive" :active="isPreviewActive" v-on:close="isPreviewActive=false">
            <div class="container">
                <vue-phone-preview :content="editorData"/>
            </div>
        </b-modal>
    </section>
</template>
<script>
    import Editor from '@tinymce/tinymce-vue'
    import Vuex from '../../store'
    import VuePhonePreview from './ChatMessagePreview';

    function removeTags(str) {
        if ((str===null) || (str===''))
            return false;
        else
            str = str.toString();
        return str.replace( /(<([^>]+)>)/ig, '').replace(/\s/g, '').replace(/&nbsp;/gi,'');
    }

    let rte = {
        name: "RichTextEditor",
        props: {
            isActive: Boolean,
            room_id: Number,
        },
        components: {
            editor: Editor,
            VuePhonePreview
        },
        created() {
        },
        data() {
            return {
                editorData: '<p dir="rtl" style="text-align: right;"></p>',
                posted: [],
                options: {
                    height: 500,
                    strict_loading_mode: true,
                    menubar: true,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount directionality'
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor forecolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | ltr rtl | image | removeformat | help',
                    file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype == 'image') {
                            var input = document.getElementById('my-file');
                            input.click();
                            input.onchange = function () {
                                var file = input.files[0];
                                var reader = new FileReader();
                                reader.onload = async function (e) {
                                    let image = await rte.methods.sendFile({data: e.target.result, type: 'image/jpeg'});
                                    callback(image, {
                                        alt: file.name
                                    });
                                };
                                reader.readAsArrayBuffer(file);
                            };
                        }
                    },
                },
                isPreviewActive: false,
            }
        },
        methods: {
            post() {

                const stripedText = removeTags(this.editorData);
                if (stripedText.length > 0) {
                    const message = {
                        "type": "rich_text",
                        "value": this.editorData,
                    };
                    this.$store.dispatch('CHAT_POST', {room_id: this.room_id, message: message});
                    this.$log.info('message: ' + JSON.stringify(message));
                    this.$emit('close');
                } else {
                    this.$log.info('empty message');
                }
            },
            cancel() {
                this.$emit('close');
            },
            async sendFile({data, type}) {
                //window.console.log(rte.$store)
                let resp = await Vuex.dispatch('FILE_UPLOAD', {data: data, type: type});
                return resp.data;
            },

        }
    }

    export default rte;
</script>

<style scoped>
    .container {
        padding: 10px;
        background: none;
        overflow: visible;
    }

    .buttons-block {
        padding: 5px 0 0 0;
        text-align: right;
    }

    .close-open div{
        display: none;
    }
</style>